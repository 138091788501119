import React from 'react'

import Layout from '../components/layout'

const AuditionPage = () => {
  return (
    <Layout>
      <div className='text-title text-3xl my-6 text-center md:my-14 lg:text-6xl'>
        Audition
      </div>
      <div className='text-primary mx-5 md:mx-auto md:max-w-xl lg:max-w-4xl xl:max-w-5xl'>
        <p>
          Visit Penn State's School of Music{' '}
          <a
            href='https://music.psu.edu/admissions/undergraduate/undergraduate-application-process'
            target='_blank'
            rel='noreferrer'
          >
            application process
          </a>{' '}
          for formal instructions on applying to Penn State and scheduling an
          audition.
        </p>
        <div className='my-4'>
          <span>
            Auditionees should play a prepared piece of their choice, and an
            etude from any of the following method books:
          </span>
          <ul className='list-disc my-4 mx-12'>
            <li>Arban Complete Method (for trombone or tuba)</li>
            <li>Blazevich 70 Studies (Vol. 1 or 2)</li>
            <li>Bordogni 43 Bel Canto Studies</li>
            <li>Rochut Melodious Etudes for trombone (Vol. 1, 2, or 3)</li>
            <li>Kopprasch 60 Selected Studies</li>
            <li>Snedecor Low Etudes for Tuba</li>
          </ul>
          <span>
            Applicants should know all major scales (two octaves), and chromatic
            scales demonstrating the full range
          </span>
        </div>
        <div className='flex flex-col my-4 mb-8'>
          <span>
            Professor of Tuba Euphonium:{' '}
            <a href='https://velvetuba.com' target='_blank' rel='noreferrer'>
              Velvet Brown
            </a>
          </span>
          <span>814-863-2126</span>
          <span>vmb10@psu.edu</span>
        </div>
      </div>
    </Layout>
  )
}

export default AuditionPage
